import React from 'react';

import Text from '../../Text';
import Heading from '../../Heading';
import puzzleGraphics from '../../../assets/puzzle-graphics.svg';
import HeaderGridSection from '../HeaderGridSection';

const HeaderSection = () => (
  <HeaderGridSection
    image={puzzleGraphics}
    mobileImageOpacity={0.3}
    alignImageRight
  >
    <Heading as="h1" fontSize={[5, 6, 8]}>
      Accelerating Blockchain Adoption
    </Heading>
    <Text mt={4}>
      Discover the advantages of decentralized networks for business use cases and prepare your organization for the strategic shift. 
    </Text>
  </HeaderGridSection>
);

HeaderSection.propTypes = {};

export default HeaderSection;
